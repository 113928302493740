import React, { lazy, ReactNode, Suspense, useContext, useState } from "react";
export const ThemeContext = React.createContext<"light" | "dark">("light");

const ThemeUpdateContext = React.createContext(() => {
  console.log("Theme update context");
});

export function useTheme() {
  return useContext(ThemeContext);
}

export function useThemeUpdate() {
  return useContext(ThemeUpdateContext);
}
interface Props {
  children?: ReactNode;
  // any props that come into the component
}
type THEME = "dark" | "light";

export function ThemeProvider({ children }: Props) {
  const [theme, setTheme] = useState<THEME>(
    (localStorage.getItem("theme") as THEME) || "light"
  );
  // here the first value is the darkTheme default value and not
  // context value

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = (prevTheme === "light" ? "dark" : "light") as THEME;
      localStorage.setItem("theme", newTheme);
      return newTheme;
    });
    window.location.reload();
  };
  const DarkTheme = lazy(() => import("./theme/dark-theme"));
  const LightTheme = lazy(() => import("./theme/light-theme"));

  return (
    <ThemeUpdateContext.Provider value={toggleTheme}>
      <ThemeContext.Provider value={theme}>
        <Suspense fallback={<span />}>
          {theme === "dark" ? <DarkTheme /> : <LightTheme />}
        </Suspense>
        {children}
      </ThemeContext.Provider>
    </ThemeUpdateContext.Provider>
  );
}
