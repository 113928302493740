import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import "antd/dist/antd.css";
import { ThemeProvider } from "./useTheme";

import "./index.css";
import { RouteManager } from "./route/RouteManager";

import { Layout, Spin } from "antd";
import { Account, AccountContext } from "@pages/LoginForm/Account";

const AppHeader = lazy(() => import("@components/AppHeader"));
const Sidebar = lazy(() => import("@components/Sidebar"));

const { Content } = Layout;

function App() {
  const [collapsed, setCollapsed] = useState<boolean>(
    localStorage.getItem("navState") === "true"
  );
  const { status } = useContext(AccountContext);
  const [isAuthenticated, setIsAuthenticated] = useState(status);

  useEffect(() => {
    const sessionData = sessionStorage.getItem("sessionData");
    if (sessionData) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [status]);

  return (
    <ThemeProvider>
      <div>
        <Layout
          style={{
            minHeight: "100vh",
          }}
        >
          {isAuthenticated && (
            <Suspense fallback={<Spin size="large" />}>
              <Sidebar collapsed={collapsed} />
            </Suspense>
          )}
          <Layout className="site-layout">
            {isAuthenticated && (
              <Suspense fallback={<Spin size="large" />}>
                <AppHeader collapsed={collapsed} setCollapsed={setCollapsed} />
              </Suspense>
            )}
            <Content
              style={{
                borderRadius: "5px",
                padding: "1rem",
                minHeight: 280,
                maxHeight: "calc(100vh - 64px)",
                overflow: "auto",
              }}
            >
              <RouteManager />
            </Content>
          </Layout>
        </Layout>
      </div>
    </ThemeProvider>
  );
}

export default App;
